import React from 'react'
import { Link, graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'
import {
	Button,
	Container,
	Grid,
	Grow,
	makeStyles,
	Typography
} from '@material-ui/core'
import { cyan, indigo, lightBlue } from '@material-ui/core/colors'

import MyTheme from '../utils/myTheme'
const theme = MyTheme

const useStyles = makeStyles(theme => ({
	backgroundImage: {
		marginTop: theme.spacing(-2),
		width: '100%',
		height: '55vh',
		backgroundPosition: 'top center',
		// backgroundPosition: 'bottom center',
		backgroundRepeat: 'repeat-y',
		backgroundSize: 'cover'
	},
	backgroundGrid: {
		minHeight: '55vh'
	},
	mainDiv: {
		minHeight: '45vh',
		backgroundColor: theme.palette.primary.main,
		// backgroundColor: cyan[500],
		paddingTop: theme.spacing(5),
		paddingBottom: theme.spacing(5)
	},
	button: {
		margin: theme.spacing(1),
		color: theme.palette.primary.main,
		backgroundColor: 'white'
	},
	bauhaus: {
		fontFamily: 'BauhausStd-Light',
		color: 'white'
	}
}))

export default ({ data }) => {
	const classes = useStyles()
	// const imageData = data.toast.childImageSharp.fluid
	return (
		<>
			<BackgroundImage
				Tag='section'
				className={classes.backgroundImage}
				fluid={data.toast.childImageSharp.fluid}
				backgroundColor={`#040e18`}
			>
				<Grid
					container
					direction='column'
					justify='center'
					alignItems='center'
					className={classes.backgroundGrid}
				>
					<Grid item xs={12} sm={10} justify='center'>
						{/* <Typography variant='h2' align='center' gutterBottom>
							Hello Gatsby
						</Typography> */}
					</Grid>
				</Grid>
			</BackgroundImage>

			<div className={classes.mainDiv}>
				<Container component='main' maxWidth='md'>
					<Grow in={true} timeout={800}>
						<>
							<Typography
								variant='h1'
								className={classes.bauhaus}
								align='center'
							>
								sol kitchen<sup style={{ fontSize: '1.5rem' }}>tm</sup>
							</Typography>
							<Typography
								variant='h4'
								className={classes.bauhaus}
								align='center'
								style={{ marginTop: -15, marginBottom: 20 }}
								gutterBottom
							>
								explore cook eat repeat
							</Typography>
							<Typography
								variant='body1'
								align='center'
								color='textPrimary'
								className={classes.bauhaus}
								style={{ marginBottom: 25, fontSize: '1.25rem' }}
								gutterBottom
							>
								Specializing in the creation and development of restaurant menu
								design, management and value of sustainable eating.
							</Typography>

							<center>
								<Link to='/about' style={{ textDecoration: 'none' }}>
									<Button
										variant='contained'
										color='primary'
										size='large'
										className={classes.button}
									>
										Come Explore
									</Button>
								</Link>
							</center>
						</>
					</Grow>
				</Container>
			</div>
		</>
	)
}

export const query = graphql`
	query {
		toast: file(name: { eq: "cookItaly-toast" }) {
			childImageSharp {
				fluid(quality: 90, maxWidth: 1920) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
	}
`
